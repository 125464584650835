import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Expander, ExpanderChevronPosition } from "@amzn/stencil-react-components/expander";
import { Flyout, FlyoutContent, FlyoutTitleRenderer } from "@amzn/stencil-react-components/flyout";
import {
  IconCalendarFill,
  IconCheckCircleFill,
  IconDesktop,
  IconExternalLink,
  IconInformationFill,
  IconLocationFill,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Col, Flex, Hr, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import DOMPurify from "dompurify";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import React, { useRef } from "react";
import ICalendarLink from "react-icalendar-link";
import { useReactToPrint } from "react-to-print";
import preDetailsHeaderImage from "../../assets/images/pre-details-header.png";
import { NHE_EVENT_TYPE, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { USCIS_URL, USCIS_URL_ES } from "../../config/urls";
import { isCountryWithoutI9 } from "../../helpers/utils";
import { useGetNHEAppointment } from "../../hooks/apis/useGetNHEAppointment";
import { useGetRTWAppointment } from "../../hooks/apis/useGetRTWAppointment";
import { ChecklistBundle, useChecklistArb } from "../../hooks/use-arb";
import { useNavigatorWithURLReady } from "../../hooks/use-navigator";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { isNheVenueTimeEnabledByAppConfig } from "../../utility/app-config-helper";
import { CONTACT_US_LINK } from "../../utility/constants";
import { getCountryForDomain, isSpanishLocale } from "../../utility/locale-helper";
import { displayVenueTime } from "../../utility/time-processor";
import { H2, Text } from "../typography";
import "./styles.scss";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";

interface AppointmentDetailsFlyoutProps {
  isOpen: boolean;
  closeFlyout: () => void;
  appointmentType: NHE_EVENT_TYPE;
}

const titleRenderer: FlyoutTitleRenderer = ({ titleText }) => (
  <H2 id="modal-title" color={"#FFFFFF"}>
    {titleText}
  </H2>
);

const addToCalendarKeyDown = (event: any, classname: string) => {
  if (event.key === " " || event.code === "Space") {
    event.preventDefault();
    (document.querySelector(classname) as any).click();
  }
};

interface GetWhatToExpectContentProps {
  country: SUPPORTED_COUNTRY;
  bundle: ChecklistBundle;
  isWithVirtualNHE: boolean;
  isRightToWorkAppointment: boolean;
}

function getWhatToExpectContent({
  country,
  bundle,
  isWithVirtualNHE,
  isRightToWorkAppointment,
}: GetWhatToExpectContentProps): string {
  if (isRightToWorkAppointment) {
    return bundle.getMessage("Checklist-RightToWorkDetails-WhatToExpect-Content-Virtual-RTW");
  }
  if (country === SUPPORTED_COUNTRY.JP) {
    // Japan uses custom messaging as there are no contingencies required before NHE and the appointments are longer
    return bundle.getMessage("Checklist-PreHireDetails-WhatToExpect-Content-Virtual-NHE-Japan");
  }
  if (isWithVirtualNHE) {
    return bundle.getMessage("Checklist-PreHireDetails-WhatToExpect-Content-Virtual-NHE");
  }
  return bundle.getMessage("Checklist-PreHireDetails-WhatToExpect-Content");
}

export const AppointmentDetailsFlyout = ({ isOpen, closeFlyout, appointmentType }: AppointmentDetailsFlyoutProps) => {
  const bundle = useChecklistArb();
  const nheAppointmentTitle = bundle.formatMessage("Checklist-CardControlled-PreHireTitle");
  const rtwAppointmentTitle = bundle.formatMessage("Checklist-Header-RTW-Appointment-Title");
  const addToCalendar = bundle.formatMessage("Checklist-AppointmentCard-ButtonText3");
  const googlePlaceURL = "http://maps.google.com/?q=";

  const nheAppointment = useGetNHEAppointment();
  const rtwAppointment = useGetRTWAppointment();

  const { sendDocumentsAnalyticsEvent, sendScheduleOrRescheduleClickEvent } = useSendAnalyticEvent();

  const appointmentV2Details_ = appointmentType === NHE_EVENT_TYPE.NHE ? nheAppointment : rtwAppointment;

  // Just show a spinner if there's an issue loading, this component shouldn't be reachable from the header in the error cases
  const appointmentV2Details = appointmentV2Details_?.errorStatusCode
    ? undefined
    : (appointmentV2Details_ as AppointmentInfo);
  const { appointmentURLService: appointmentSelfServiceURL, rtwAppointmentSelfServiceURL } = useUrlBuilder();

  const { isEligibleForNheAppointmentRescheduling, isEligibleForRtwAppointmentRescheduling } =
    useAppSelector(getFeatureFlag);

  const CustomRow = (children: any) => <Row gridGap="S200">{children}</Row>;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current;
    },
  });

  const detailsTitleMessage = bundle.getMessage("Checklist-CardControlled-DetailsTitle");
  const cannotAttendMessage = bundle.formatMessage("Checklist-PreHireDetails-CannotAttend");
  const whatToExpectMessage = bundle.getMessage("Checklist-PreHireDetails-WhatToExpect");
  const printPageMessage = bundle.getMessage("Checklist-PreHireDetails-PrintPage");
  const whatToBringMessage = bundle.getMessage("Checklist-PreHireDetails-WhatToBring");
  const whenAndWhereMessage = bundle.getMessage("Checklist-PreHireDetails-WhenAndWhere");
  const whatToBringLinkMessage = bundle.getMessage("Checklist-PreHireDetails-WhatToBring-Link");
  const requestAccommodationsMessage = bundle.getMessage("Checklist-PreHireDetails-RequestAccommodations");
  const pleaseClickHereMessage = bundle.getMessage("checklist-General-PleaseClickHere");
  const rescheduleMessage = bundle.getMessage("Checklist-AppointmentCard-ButtonText2");
  const startTimeMoment = moment.utc(appointmentV2Details?.startTimestamp);
  const getDirectionsMessage = bundle.formatMessage("Checklist-PreHireDetails-GetDirections");
  const dateMessage = bundle.formatMessage("Checklist-PreHireDetails-Date", {
    date: startTimeMoment,
  });

  const timeMessage = appointmentV2Details?.displayReadyStartDate
    ? `${appointmentV2Details?.displayReadyStartDate} ${appointmentV2Details.displayReadyTimeSlot}`
    : appointmentV2Details?.displayReadyTimeSlot;

  const whenAndWhereContentMessage =
    appointmentType == NHE_EVENT_TYPE.RTW ? bundle.getMessage("Checklist-RightToWorkDetails-WhenAndWhere-Content") : "";

  const supportLink = (
    <Link target="_blank" href={CONTACT_US_LINK}>
      {pleaseClickHereMessage}
    </Link>
  );
  const specialAssistDetailMessage = bundle.formatMessage("Checklist-PreHireDetails-AssistDetails1", {
    supportLink: supportLink,
  });

  const country = getCountryForDomain();
  const displayWhatToBring = country !== SUPPORTED_COUNTRY.JP;
  const isVirtual = appointmentV2Details?.locationType === "PHYSICAL" ? false : true;
  const whatToExpectContent = getWhatToExpectContent({
    country,
    bundle,
    isWithVirtualNHE: isVirtual,
    isRightToWorkAppointment: appointmentType == NHE_EVENT_TYPE.RTW,
  });
  const uscis_link = isSpanishLocale() ? USCIS_URL_ES : USCIS_URL;
  const viewDocumentsLink =
    appointmentType == NHE_EVENT_TYPE.RTW
      ? bundle.getMessage("Checklist-RightToWorkDetails-WhatToBring-Content")
      : isCountryWithoutI9(country)
      ? bundle.getMessage("Checklist-PreHireDetails-WhatToBring-Content-Without-I9")
      : bundle.formatMessage("Checklist-PreHireDetails-WhatToBring-Content", {
          documentLink: (
            <Link target="_blank" href={uscis_link}>
              {whatToBringLinkMessage}
            </Link>
          ),
        });

  const displayReadyLocation = appointmentV2Details ? appointmentV2Details.displayReadyLocation : null;

  const physicalLocationGoogleMapLink = appointmentV2Details && (
    <Link fontSize={"T200"} href={googlePlaceURL + appointmentV2Details.displayReadyLocation} target={"_blank"}>
      {displayReadyLocation}
    </Link>
  );

  const handleJoinHereClick = () => {
    sendDocumentsAnalyticsEvent("join here click", appointmentV2Details?.locationType || "");
  };

  const virtualLinkCompleteMessage = displayReadyLocation ? (
    <Link
      icon={<IconExternalLink size={IconSize.ExtraSmall} title={bundle.getMessage("Checklist-external-link")} />}
      tabIndex={0}
      fontSize={"T200"}
      href={displayReadyLocation}
      target={"_blank"}
      margin={{ left: 5 }}
    >
      <span onClick={handleJoinHereClick}>
        {appointmentV2Details?.locationType === "VIRTUAL_CONNECT"
          ? bundle.formatMessage("Checklist-Appointment-JoinHere")
          : displayReadyLocation}{" "}
      </span>
    </Link>
  ) : (
    bundle.getMessage("Checklist-Common-Virtual")
  );

  const displayReadyVirtualLocation = bundle.formatMessage("Checklist-PreHireDetails-Location-Virtual-NHE", {
    address: virtualLinkCompleteMessage,
  });

  const recheduleLinkOnClick = useNavigatorWithURLReady(
    appointmentType === NHE_EVENT_TYPE.NHE ? appointmentSelfServiceURL : rtwAppointmentSelfServiceURL
  );

  const handleRecheduleLinkOnClick = () => {
    sendScheduleOrRescheduleClickEvent(appointmentType);
    recheduleLinkOnClick();
  };

  const displayReadyPhysicalLocation = bundle.formatMessage("Checklist-PreHireDetails-Location", {
    address: physicalLocationGoogleMapLink,
  });

  const locationDetailsMessage = isVirtual ? displayReadyVirtualLocation : displayReadyPhysicalLocation;

  const nheCalendarEvent = {
    startTime: appointmentV2Details?.startTimestamp || "",
    endTime: appointmentV2Details?.endTimestamp || "",
    title: nheAppointmentTitle,
    location: appointmentV2Details?.displayReadyLocation || "",
  };

  const rtwCalendarEvent = {
    startTime: appointmentV2Details?.startTimestamp || "",
    endTime: appointmentV2Details?.endTimestamp || "",
    title: rtwAppointmentTitle,
    location: appointmentV2Details?.displayReadyLocation || "",
  };

  if (!appointmentV2Details) {
    return <Spinner size={SpinnerSize.Small} showText={false}></Spinner>;
  }

  const renderAppointmentPublicText = appointmentV2Details && appointmentV2Details.appointmentPublicText ? true : false;
  const printMessageButton = (
    <Button className="print-link" onClick={handlePrint}>
      {printPageMessage}
    </Button>
  );

  const dynamicAppointmentPublicText = renderAppointmentPublicText ? (
    <Col gridGap="S300">
      <Row justifyContent="space-between" alignItems="center">
        <H2>{whatToExpectMessage}</H2>
        {printMessageButton}
      </Row>
      <Row justifyContent="space-between" alignItems="center">
        {/*Extra check to resolve types so that ReactHtmlParser doesn't complain.*/}
        <Text>
          {appointmentV2Details && appointmentV2Details.appointmentPublicText && (
            <div
              className="sf-html-container"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(appointmentV2Details.appointmentPublicText as string),
              }}
            ></div>
          )}
        </Text>
      </Row>
    </Col>
  ) : (
    <Row>
      <Col gridGap="S200">
        <Row justifyContent="space-between" alignItems="center">
          <H2>{whatToExpectMessage}</H2>
          {printMessageButton}
        </Row>
        {isVirtual ? (
          <Text fontSize="T100" className="what-to-expect-content">
            <Markdown>{whatToExpectContent}</Markdown>
          </Text>
        ) : (
          <Text fontSize="T100">{whatToExpectContent}</Text>
        )}
      </Col>
    </Row>
  );

  return (
    <Flyout
      data-testid={"appointment-v2-details-flyout"}
      isOpen={isOpen}
      close={closeFlyout}
      aria-labelledby={"modal-title"}
    >
      <FlyoutContent
        titleText={detailsTitleMessage}
        renderTitle={titleRenderer}
        buttons={[
          <Row key="details-buttons" width="100%" justifyContent="space-around" alignItems="center">
            <Row gridGap="1rem">
              {
                <Button
                  data-testid={`pre-hire-flyout-add-to-calendar-${appointmentType}`}
                  variant={ButtonVariant.Primary}
                  as={"Col"}
                  size={ButtonSize.Small}
                  onKeyDown={(event) => addToCalendarKeyDown(event, ".add-to-calendar-pre-hire-appointment")}
                  tabIndex={-1}
                >
                  <ICalendarLink
                    key="calendar-button"
                    filename={appointmentType === NHE_EVENT_TYPE.RTW ? rtwAppointmentTitle : nheAppointmentTitle}
                    event={appointmentType === NHE_EVENT_TYPE.RTW ? rtwCalendarEvent : nheCalendarEvent}
                    className={"add-to-calendar"}
                  >
                    {addToCalendar}
                  </ICalendarLink>
                </Button>
              }
              <a
                data-testid={`pre-hire-flyout-get-directions-${appointmentType}`}
                href={googlePlaceURL + (appointmentV2Details?.displayReadyLocation || "")}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={-1}
                aria-hidden="true"
                className="get-directions"
                style={{ textDecoration: "none" }}
              >
                {!isVirtual && (
                  <Button variant={ButtonVariant.Primary} size={ButtonSize.Small}>
                    {getDirectionsMessage}
                  </Button>
                )}
              </a>
            </Row>
          </Row>,
        ]}
        onCloseButtonClick={closeFlyout}
      >
        <img src={preDetailsHeaderImage} width="100%" alt="" />
        <Col className="flyout-content" gridGap="S300" ref={componentRef} data-testid="pre-hire-flyout-content">
          {dynamicAppointmentPublicText}
          {displayWhatToBring && !renderAppointmentPublicText && (
            <Row>
              <Col gridGap="S200">
                <H2>{whatToBringMessage}</H2>
                <Text fontSize="T100">{viewDocumentsLink}</Text>
              </Col>
            </Row>
          )}
          <Row>
            <View width="100%">
              <Expander titleText={whenAndWhereMessage} chevronPosition={ExpanderChevronPosition.Trailing}>
                <Row style={{ marginBottom: "4%" }}>
                  {whenAndWhereContentMessage && (
                    <Col gridGap="S300">
                      <Text fontSize="T100">{whenAndWhereContentMessage}</Text>
                    </Col>
                  )}
                </Row>
                <Col gridGap="S300" data-testid="prehire-flyout-when-and-where">
                  <CustomRow
                    {...(
                      <>
                        <IconCalendarFill />
                        <Text data-testid={`prehire-flyout-date-${appointmentV2Details?.startTimestamp}`}>
                          {dateMessage}
                        </Text>
                      </>
                    )}
                  ></CustomRow>
                  <CustomRow
                    {...(
                      <>
                        <IconCheckCircleFill />
                        <Text data-testid={`prehire-flyout-time-${appointmentV2Details?.startTimestamp}`}>
                          {timeMessage}
                        </Text>
                      </>
                    )}
                  ></CustomRow>
                  <CustomRow
                    {...(
                      <>
                        {isVirtual ? (
                          <IconDesktop size={IconSize.Small} />
                        ) : (
                          <IconLocationFill size={IconSize.Medium} />
                        )}
                        {isVirtual ? (
                          <Flex flexDirection="row" alignItems="center">
                            <Text data-testid={`prehire-flyout-location-${appointmentV2Details.displayReadyLocation}`}>
                              {locationDetailsMessage}
                            </Text>
                          </Flex>
                        ) : (
                          <Text data-testid={`prehire-flyout-location-${appointmentV2Details.displayReadyLocation}`}>
                            {locationDetailsMessage}
                          </Text>
                        )}
                      </>
                    )}
                  ></CustomRow>
                  <CustomRow
                    {...(
                      <>
                        <IconInformationFill size={IconSize.Small} />
                        <Text data-testid={`prehire-flyout-location-public-text`}>
                          {appointmentV2Details ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(appointmentV2Details.locationPublicText as string),
                              }}
                            />
                          ) : null}
                        </Text>
                      </>
                    )}
                  ></CustomRow>
                  {(appointmentType == NHE_EVENT_TYPE.NHE
                    ? isEligibleForNheAppointmentRescheduling
                    : isEligibleForRtwAppointmentRescheduling) && (
                    <Row justifyContent="space-between" alignItems="center">
                      <Text fontSize="T100">
                        <strong>{cannotAttendMessage}</strong>
                      </Text>
                      <Button
                        as={"a"}
                        size={ButtonSize.Small}
                        data-testid="prehire-flyout-reschedule"
                        onClick={handleRecheduleLinkOnClick}
                      >
                        {rescheduleMessage}
                      </Button>
                    </Row>
                  )}
                </Col>
              </Expander>
            </View>
          </Row>
          <Hr />
          <Row>
            <View width="100%">
              <Expander
                aria-label="request-accomodations"
                titleText={requestAccommodationsMessage}
                chevronPosition={ExpanderChevronPosition.Trailing}
              >
                <Col gridGap="S300">
                  <Row gridGap="S200">
                    <Text data-testid="prehire-flyout-request-accomodations-text">{specialAssistDetailMessage}</Text>
                  </Row>
                </Col>
              </Expander>
            </View>
          </Row>
          <Hr />
        </Col>
      </FlyoutContent>
    </Flyout>
  );
};
